import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cofeed-19.github.io"
        }}><strong parentName="a">{`CoFeed-19`}</strong>{`: Client-only Feed Reader, Free, No tracking, Minimalist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/strdr4605/mockingcase"
        }}><strong parentName="a">{`mockingcase`}</strong>{`: node package that converts a string to mOcKiNgCaSe`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/strdr4605/jump2header"
        }}><strong parentName="a">{`jump2header`}</strong>{`: add "jump to" links for markdown headers`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      